import { useState, useRef } from "react";
import {T, PUC} from '../PopUpJK';
import './control.css';

export default function Controls ({scoreDisplay,width, setWidth, setPause, reset, pause}) {
  const [,setP, , setAbout] = PUC();
  const [tm, setTm] = useState(5);
  const [time, setTime] = useState(1000 * 60 * tm);
  //const W = useRef(null);
  //const Tm = useRef(null);
  const timeRef = useRef(null);

  function SetWT () {
    setWidth(Number(width));
    setTime(Number(tm * 1000 * 60));
    setPause(false);
    timeRef.current = setInterval(()=> {setTime(prev => prev - 1000)}, 1000);
  };
  
  function StopGame () {
    clearInterval(timeRef.current);
    setTime(Number(tm * 1000 * 60));
    setP({option: 0, open:true, message:`Game is up.  Your score is ${scoreDisplay}.\n`});
    reset(); 
  };
  //if time <0, stop
  if (time <= 0) {
    StopGame();
  }

  const setButtonStyle = {
    border: '0px',
    backgroundColor: 'rgb(103, 135, 242)',  
    color: 'white',
    textAlign:'center',
    width:'50px',
    borderRadius: '5px'
  };
  const labelStyle = {
    width:'50px', 
    textAlign:'center',
    color: 'white',
    backgroundColor: ' rgb(162, 130, 12)',
    borderRadius: '5px'
  };
  const inputStyle = {
    width:'50px', 
    textAlign:'center',
    borderRadius: '5px'
  };
  const scoreStyle = {
    width:'50px', 
    textAlign:'center',
    backgroundColor:'white', 
    border: '1px solid black',
    borderRadius: '5px'
  };

  const overlayStyle = {
    backgroundColor: 'transparent',
  position:'absolute',
  width:'58%',
  height:'100%',
  top:'0px',
  left:'0px',
    zIndex: '10',
  }

    return (
      <>
        <div style={{height:'20px', backgroundColor: 'lightblue' }}></div>
        <div style={{position:'relative', display:'flex',justifyContent: 'center', backgroundColor: 'lightblue' }}>
          <div style={{display:'flex',justifyContent: 'center', backgroundColor:'rgb(241, 236, 137)',  borderRadius:'5px' }}>
          &nbsp;
          <div style={labelStyle} title='number of candies in a row' >Size:&nbsp; </div>
          <input style={inputStyle} type='number' value={width} min={5} max={14} onChange={e=>setWidth(e.target.value)} />&nbsp;
          <div style={labelStyle}>Score:&nbsp; </div>
          <div style={scoreStyle}>{scoreDisplay}</div>&nbsp;
          <div style={labelStyle} title='time setting in minutes' >Time:&nbsp; </div>
          <input style={inputStyle} type='number' value={tm} min={3} max={20} onChange={e=>{setTm(e.target.value);setTime(e.target.value * 1000 * 60);}} />
          <div style={scoreStyle} title='remaining time in seconds'>{time / 1000}</div>&nbsp;
          {
            pause &&
            <button onClick={SetWT} style={setButtonStyle}  title='set the game size and the playing time and start.'>Start</button>
          }
          {
            !pause &&
            <button onClick={StopGame}  style={setButtonStyle} title='stop the game'>Stop</button>
          }
          &nbsp;
          <button onClick={()=>setAbout(true)}  style={setButtonStyle} title='About'>About</button>
          &nbsp;
          </div>
          <div title='"Size" and "Time" not to be changed now' style={ pause ? {display:'none'} : overlayStyle }></div>
        </div>
      </>
    );

};
