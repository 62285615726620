import React from 'react'
import './index.css'
import App from './App'
import {PU} from './PopUpJK';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PU>
      <App />
    </PU> 
  </React.StrictMode>,
);

