import { createContext, useContext, useState } from 'react';
import {useRef} from 'react';
import './PopUpJK.css';
import aboutText from './aboutText';
/*
export const T = ({r, s, w, t=20, l=20}) => {
    return (
      <div className="tooltip">
        {r}
        <div 
          className="tooltiptext" 
          style={w ? {width: `${w}px`, top:`${t}px`, left:`${l}px`}
                   : {top:`${t}px`, left:`${l}px`}}
        >
          {s}
        </div>
      </div>
    );
};*/

export const T = ({r, s, w, t=20, l=20}) => {
  return (
    <span className="tooltip">
      {r}
      <span 
        className="tooltiptext" 
        style={w ? {width: `${w}px`, top:`${t}px`, left:`${l}px`}
                 : {top:`${t}px`, left:`${l}px`}}
      >
        {s}
      </span>
    </span>
  );
};

const PopUpJK = ({p, setP}) => {

    const r = useRef();
  
    const btnStyle = {
      backgroundColor:'red',
      color: 'white',
      borderRadius:'3px',
      borderColor:'transparent',
      float:'right',
      cursor:'pointer',
    };
  
    const spanStyle = {
      float:'right',
      backgroundColor: 'rgb(108, 108, 245)',
    };
  
    return (
      <>
        {
          p.option === 0 &&
          <div className={ p.open ? 'popupJK puShow' : 'popupJK'}>
            {p.message}
            <br />
            <br />
            <
              button 
              style={btnStyle} 
              onClick={()=>setP({...p, open:false})}
            >
              X
            </button>
          </div>
        }
        {
          p.option === 1 &&
          <div className={p.open ? 'popupJK puShow' : 'popupJK'}>
            {p.message}
            <br />
            <br />
            <
              button 
              style={btnStyle} 
              onClick={()=>{setP({...p, open:false}); p.CBX();}}
            >
              X
            </button>
            <span style={spanStyle} >&nbsp;</span>
            <
              button 
              style={btnStyle} 
              onClick={()=>{ setP({...p, open:false});p.CBO();}}
            >
              O
            </button>
          </div>
        }
        {
          p.option === 2 &&
          <div className={p.open ? 'popupJK puShow' : 'popupJK'}>
            {p.message}
            <br />
            <input type='text' ref={r} />
            <br />
            <br />
            <
                button 
                style={btnStyle} 
                onClick={()=>{setP({...p, open:false}); p.CB(r.current.value);r.current.value='';}}
            >
              X
            </button>
          </div>
        }
        {
          (p.option === 0 ||p.option === 1|| p.option === 2) &&
          <div className={ p.open ? 'overlay ovlShow' : 'overlay'}></div>
        } 
      </>
    );
  }

  const About = ({about, setAbout}) => {
    return (
      <div className={`${about ? "modal-container isOpen" : "modal-container"}`} >
          <p style={{color:'white'}}> 
            {aboutText}
          </p>
          <button className="close-btn" onClick={()=>setAbout(false)}>
            close
          </button>
      </div>
    );
  };
  
const PUContext = createContext(null);

export function PU({children}) {
  const [p, setP] = useState({option: 0, open:false, message:'', CBO:null, CBX: null, CB:null});
  const [about, setAbout] = useState(false);

  return (
    <PUContext.Provider value={[p, setP,about, setAbout]} >
     {children}
     <PopUpJK p={p} setP={setP} />
     <About about={about} setAbout={setAbout} />
    </PUContext.Provider>
  )
}

export const PUC = () => useContext(PUContext);
 