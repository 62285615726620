import {useEffect, useState} from 'react'
import blueCandy from './images/blue-candy.png'
import greenCandy from './images/green-candy.png'
import orangeCandy from './images/orange-candy.png'
import purpleCandy from './images/purple-candy.png'
import redCandy from './images/red-candy.png'
import yellowCandy from './images/yellow-candy.png'
import './App.css';
import Controls from './components/controls';
import {PUC} from './PopUpJK';

var pointsSound = new Audio('/points.wav');

const candyColors = [
    blueCandy,
    orangeCandy,
    purpleCandy,
    redCandy,
    yellowCandy,
    greenCandy
]

const App = () => {
    const [currentColorArrangement, setCurrentColorArrangement] = useState([])
    const [scoreDisplay, setScoreDisplay] = useState(0)
    const [width, setWidth] = useState(6)
    const [game, setGame] = useState(true)
    const [pause, setPause] = useState(true)
    //const [about, setAbout] = useState(false)
    const [, setP] = PUC()

    
    const numCandies = width * width;

    const drag = {start:null, end:null};

    const reset = () => {
        setCurrentColorArrangement([]);
        setScoreDisplay(0);
        setGame(!game);
        setPause(true);
    };

    const rearrangeColumn = (index, colSize, width, colors) => {
      let i = index;
      while (i >= width) {
        colors[i] = colors[i - width * colSize];
        i -= width;
      }
      let k = i + width * (colSize - 1);
      for (let j = 0; j < colSize; j++) {
        let randomNumber = Math.floor(Math.random() * candyColors.length);
        colors[k - width * j] = candyColors[randomNumber];
      }
    };

    const rearrangeRow = (index, rowSize, width, colors) => {
        for (let i = 0; i < rowSize; i++) {
            rearrangeColumn(index - i, 1, width, colors);
        }
    }; 

    const CheckGetRows = (option, el, rowSize, colors) => {
        for (let i = numCandies - 1; i > 0; i--) {
            if (i % width + 1 < rowSize) {
                continue;
            }
            const row = [];
            for (let j = 0; j < rowSize; j++) {
                row.push(i - j);
            }

            const selColor = colors[i]

            if (row.every(square => colors[square] === selColor)) {
                if (option === 'check') {
                  return row.includes(el) ? rowSize : 1;
                } else {
                    rearrangeRow(i, rowSize, width, colors);
                    return 1;
                }           
            }
        }
        return 0;
    }

    const CheckGetColumns = (option, el, colSize, colors) => {
        const endIndex = (colSize - 1) * width - 1;
        for (let i = numCandies - 1; i > endIndex; i--) {
            const column = [];
            for (let j = 0; j < colSize; j++) {
                column.push(i - width * j);
            }

            const selColor = colors[i]

            if (column.every(square => colors[square] === selColor)) {
                if (option === 'check') {
                  return column.includes(el) ? colSize : 1;
                } else {
                    rearrangeColumn(i, colSize, width, colors);
                    return 1;
                }            
            }
        }
        return 0;
    }

    const findScores = (el, colors) => 
      CheckGetRows('check', el, 4, colors)
      || CheckGetRows('check', el, 3, colors)
      || CheckGetColumns('check', el, 4, colors)
      || CheckGetColumns('check', el, 3, colors)

    const getScores = (el, colors) => {
      CheckGetRows('get', el, 4, colors);
      CheckGetRows('get', el, 3, colors);
      CheckGetColumns('get', el, 4, colors);
      CheckGetColumns('get', el, 3, colors);
    }

    const dragStart = (e) => {
        if (pause === false) {
            drag.start = e.target;
        } else {
          setP({option: 0, open:true, message:'To play, press the "Start" button.'});
        }
    }

    const dragDrop = (e) => {
        if (pause === false) {
            drag.end = e.target;
        }     
    }

    const dragEnd = () => {
        if (pause === true) {
          return;
        }  
        const squareBeingDraggedId = parseInt(drag.start.getAttribute('data-id'))
        const squareBeingReplacedId = parseInt(drag.end.getAttribute('data-id'))

        const Dist = Math.abs(squareBeingDraggedId - squareBeingReplacedId )

        const validMove = (Dist === 1 || Dist === width);

        if (! validMove) {
            drag.start=null;
            drag.end=null;
            setP({option: 0, open:true, message:'Not a valid move. Try again.'});
            return;
        }

        currentColorArrangement[squareBeingReplacedId] = drag.start.getAttribute('src')
        currentColorArrangement[squareBeingDraggedId] = drag.end.getAttribute('src')

       let score0 = findScores(squareBeingReplacedId, currentColorArrangement);
       let score1 = findScores(squareBeingDraggedId, currentColorArrangement);
       let score = (score0 === 1 ? 0 : score0) + (score1 === 1 ? 0 : score1);

        if (score) {
            pointsSound.play();
            setScoreDisplay(scoreDisplay + score);
            while(score) {
                getScores(-1, currentColorArrangement);
                score = findScores(-1, currentColorArrangement);
            };

        } else {
            currentColorArrangement[squareBeingReplacedId] = drag.end.getAttribute('src')
            currentColorArrangement[squareBeingDraggedId] = drag.start.getAttribute('src')
            setP({option: 0, open:true, message:'No points. Not a valid move. Try again.'});
        }
        drag.start=null;
        drag.end=null;
        setCurrentColorArrangement([...currentColorArrangement])
    }

    useEffect(() => {
        const randomColorArrangement = []
        for (let i = 0; i < numCandies; i++) {
            const randomColor = candyColors[Math.floor(Math.random() * candyColors.length)]
            randomColorArrangement.push(randomColor)
        }      

        let found = findScores(-1, randomColorArrangement);
            while(found) {
                getScores(-1, randomColorArrangement);
                found = findScores(-1, randomColorArrangement);
            };

        setCurrentColorArrangement(randomColorArrangement)
        setScoreDisplay(0);
    }, [numCandies, game])

    const gameSize = String(70 * width) + 'px';
    const candySize = String(70) + 'px';

    const x = {
        width: gameSize,
        height: gameSize,
        borderRadius: '5px',
      }

      const y = {
        width: candySize,
        height: candySize
      }
      console.log(blueCandy, 'blueCandy');
    return (
      <>
        <div>
        < Controls 
          scoreDisplay={scoreDisplay} 
          width={width}
          setWidth={setWidth} 
          setPause={setPause} 
          reset={reset} 
          pause={pause}
        />
        </div>

        <div className="app" style={{display:'flex',justifyContent:'center', backgroundColor: 'lightblue'}}>          
            <div className='board' style={x}>
                {currentColorArrangement.map((candyColor, index) => (
                    < img
                        key={index}
                        src={candyColor}
                        alt={candyColor}
                        data-id={index}
                        draggable={true}
                        onDragStart={dragStart}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => e.preventDefault()}
                        onDragLeave={(e) => e.preventDefault()}
                        onDrop={dragDrop}
                        onDragEnd={dragEnd}
                        style={y}
                    />
                ))}
            </div>        
        </div>
      </>
    );
}

export default App;

