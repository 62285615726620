const aboutText = 
<>
How to play Candy Crush. <br />
1. When you make three or four candies in a row or column, you get 3 or 4 points.<br />
   The goal of the game is to get as many points as possible in the set time.<br />
2. You click any candy and drag it in the board. <br />
   But can move the candy only by one row-wise or column-wise.<br />
3. When you make three or four candies in a row or column, the moved candy <br />
   and the candy in the receiving position chanes the position. <br />
   If no points, the move is invalid and the moved candy goes back to its original<br />
   position. <br />
4. During the "Set Up" mode, when a blue button shows the "Start" caption,<br />
   adjust the size of the board and the playing time. And click the "Start" button.<br />
5. Now, the caption is turned to "Stop" and play is allowed.<br />
6. But, change of game parameters are not allowed.<br />
</>;

export default aboutText;

